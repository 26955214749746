import React, { Fragment, useEffect, useState } from "react";
import styles from "../Songs.module.css";
import {
  setActiveSong,
  setActiveUserPlaylist
} from "../../../store/actions/songActions";
import { useDispatch, useSelector } from "react-redux";
import { ReduxAppState } from "../../../store/reducers";
import { CommandButton, IconButton } from "@fluentui/react";
import SongSheetMusicsListView from "../sheetMusic/SongSheetMusicsListView";
import SongDetailView from "../song/SongDetailView";
import { PlaylistUtility } from "../../../utility/v2/playlistUtility";
import { SongUtilityV2 } from "../../../utility/v2/songUtility";
import { SheetMusicFileUtility } from "../../../utility/v2/sheetMusicFileUtilty";
import YoutubePlayerArea from "../../shared/YoutubePlayerArea";

declare interface SongViewProp {
  songTitle: string;
  enableDelete?: boolean;
}

const SongView = (props: SongViewProp) => {
  const [song, setSong] = useState(undefined as any);
  const dispatch = useDispatch();

  const activeSong = useSelector((state: ReduxAppState) => {
    return state.song.activeSong;
  });
  const activeUserPlaylist = useSelector((state: ReduxAppState) => {
    return state.song.activeUserPlaylist;
  });

  useEffect(() => {
    SongUtilityV2.getSong(props.songTitle).then((result: any) => {
      if (result) {
        try {
          SheetMusicFileUtility.getSongSheetMusicFileSasUrls(props.songTitle).then((result1: any) => {
            result.sheetMusicFiles = { sheetMusics: result1 };
            setSong(result);
          });
        } catch {
          setSong(result);
        }
      }
    });

  }, [props.songTitle]);


  const expandButton = (song: any) => {
    return (
      <span className={ styles.commandcell }>
        <IconButton
          iconProps={ {
            iconName:
              activeSong && song.id === activeSong.id
                ? "ChevronUp"
                : "ChevronDown"
          } }
          onClick={ () => {
            if (activeSong && song.id === activeSong.id) {
              dispatch(setActiveSong(undefined as any));
            } else {
              dispatch(setActiveSong(song));
            }
          } }
        ></IconButton>
      </span>
    );
  };

  const deleteSongTitleFromList = () => {
    if (window.confirm("确定把这首歌从歌单删除？")) {
      if (activeUserPlaylist && activeUserPlaylist.songTitles) {
        const index = activeUserPlaylist.songTitles.indexOf(props.songTitle);
        if (index > -1) {
          activeUserPlaylist.songTitles.splice(index, 1);
          PlaylistUtility.UpsertPlaylist(
            activeUserPlaylist
          );
          dispatch(setActiveUserPlaylist({ ...activeUserPlaylist }));
        }
      }
    }
  };

  const moveUp = () => {
    if (activeUserPlaylist && activeUserPlaylist.songTitles) {
      const index = activeUserPlaylist.songTitles.indexOf(props.songTitle);
      if (index > 0) {
        const temp = activeUserPlaylist.songTitles[index];
        activeUserPlaylist.songTitles[index] = activeUserPlaylist.songTitles[index - 1];
        activeUserPlaylist.songTitles[index - 1] = temp;

        PlaylistUtility.UpsertPlaylist(
          activeUserPlaylist
        );
        dispatch(setActiveUserPlaylist({ ...activeUserPlaylist }));
      }
    }
  };

  const moveDown = () => {
    if (activeUserPlaylist && activeUserPlaylist.songTitles) {
      const index = activeUserPlaylist.songTitles.indexOf(props.songTitle);
      if (index < activeUserPlaylist.songTitles.length - 1) {
        const temp = activeUserPlaylist.songTitles[index];
        activeUserPlaylist.songTitles[index] = activeUserPlaylist.songTitles[index + 1];
        activeUserPlaylist.songTitles[index + 1] = temp;

        PlaylistUtility.UpsertPlaylist(
          activeUserPlaylist
        );
        dispatch(setActiveUserPlaylist({ ...activeUserPlaylist }));
      }
    }
  };



  return (
    <Fragment>
      <div style={ { height: "100%" } }>
        { song && (
          <div className={ styles.searchresult } style={ { overflow: "auto" } }>
            <div
              style={ { marginBottom: 2, backgroundColor: "white", padding: 10 } }
            >
              <div
                style={ {
                  marginBottom: 1,
                  paddingBottom: 10,
                  display: "inline-block",
                  width: "100%"
                } }
              >
                <div
                  style={ {
                    display: "inline-block",
                    width: "calc(100% - 160px)"
                  } }
                >
                  <div style={ { width: "100%" } }>
                    <span style={ { fontSize: 18 } }>{ song.title1 }</span>
                    { props.enableDelete && <CommandButton
                      title={ "Move up" }
                      onClick={ () => moveUp() }
                      iconProps={ { iconName: "Up" } }
                    ></CommandButton> }
                    { props.enableDelete &&
                      <CommandButton
                        title={ "Move down" }
                        onClick={ () => moveDown() }
                        iconProps={ { iconName: "Down" } }
                      ></CommandButton> }
                    { props.enableDelete && (
                      <CommandButton
                        title={ "从歌单删除" }
                        onClick={ () => deleteSongTitleFromList() }
                        iconProps={ { iconName: "Delete" } }
                      ></CommandButton>
                    ) }
                    <span
                      style={ {
                        float: "right",
                        paddingLeft: 5,
                        paddingRight: 5
                      } }
                    >
                      <span>{ song.tags }</span>
                    </span>
                    <SongSheetMusicsListView
                      sheetMusicFiles={ song.sheetMusicFiles }
                    ></SongSheetMusicsListView>
                  </div>
                  { !(activeSong && activeSong.id === song.id) && (
                    <>
                      <div><span>唱法: </span><span>{ song.sequence }</span></div>
                      <div style={ { color: "gray" } }>
                        { song.lyrics.substring(0, 100) + "..." }
                      </div>
                    </>
                  ) }
                </div>
                <div
                  style={ {
                    display: "inline-block",
                    minWidth: 160,
                    alignItems: "top",
                    float: "right"
                  } }
                >
                  <div style={ { display: "inline-block" } }>
                    <YoutubePlayerArea youtubeUrls={ song.youtubeUrls } />
                  </div>
                  <div style={ { display: "inline-block", float: "right" } }>
                    { " " }
                    { expandButton(song) }
                  </div>
                </div>
              </div>
              { activeSong && activeSong.id === song.id && (
                <div style={ { display: "inline-block", width: "100%" } }>
                  <SongDetailView></SongDetailView>
                </div>
              ) }
            </div>
          </div>
        ) }
      </div>
    </Fragment>
  );
};

export default SongView;
