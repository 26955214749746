import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReduxAppState } from "../../../store/reducers";
import {
  Playlist,
  setActiveUserPlaylist,
  setUserPlaylists
} from "../../../store/actions/songActions";
import UserPlaylistSongsView from "./UserPlaylistSongsView";
import { IconButton } from "@fluentui/react";
import { EasySlidesScheduleUtilty } from "../../../utility/easySlidesScheduleUtilty";
import { UserPlaylistCommandBarView } from "./UserPlaylistCommandBarView";
import EasyslidesSchedulesView from "./EasyslidesSchedulesView";
import { PlaylistUtility } from "../../../utility/v2/playlistUtility";
import { SongUtilityV2 } from "../../../utility/v2/songUtility";
import { UserRolesEnum, UserService } from "../../../utility/userService";

const UserPlaylistsView = () => {
  const [listMode, setListMode] = useState(0);
  const [youtubeUrl, setYoutubeUrl] = useState(undefined as any);
  const [sharedUserPlaylists, setSharedUserPlayLists] = useState(
    undefined as any
  );

  const activeUserPlaylist = useSelector((state: ReduxAppState) => {
    return state.song.activeUserPlaylist;
  });
  const userPlaylists = useSelector((state: ReduxAppState) => {
    return state.song.userPlaylists;
  });
  const userRoles = useSelector((state: ReduxAppState) => {
    return state.userAccount.userProfile?.roles;
  });
  const currentUser = useSelector((state: ReduxAppState) => {
    return state.userAccount.currentUser;
  });

  const dispatch = useDispatch();

  useEffect(() => {
    generateYoutubeUrl();
  }, [activeUserPlaylist]);

  const loadUserPlaylists = async () => {
    if (currentUser && currentUser.email && UserService.AuthorizeUserRole(userRoles, UserRolesEnum.Read)) {
      const playlists2 = await PlaylistUtility.getPlaylists();
      const playlists3 = await PlaylistUtility.getSharedPlaylists();

      dispatch(setUserPlaylists(playlists2));
      dispatch(setActiveUserPlaylist(undefined as any));
      setSharedUserPlayLists(playlists3);
    }
  };

  useEffect(() => {
    loadUserPlaylists();
  }, []);

  useEffect(() => {
    loadUserPlaylists();
  }, [currentUser]);

  useEffect(() => {
    loadUserPlaylists();
  }, [userRoles]);

  const deleteList = () => {
    if (window.confirm("确定删除歌单？")) {
      if (activeUserPlaylist) {
        PlaylistUtility.deletePlaylist(activeUserPlaylist);
        loadUserPlaylists();
      }
    }
  };

  const renderPlaylists = () => {
    const renderedPlaylist = [] as any;
    if (userPlaylists) {
      const sortedPlaylists = userPlaylists.sort((a: any, b: any) => {
        return a["playlistName"] > b["playlistName"] ? 1 : -1;
      });

      for (let index = 0; index < sortedPlaylists.length; index++) {
        const data1 = sortedPlaylists[index];

        renderedPlaylist.push(
          <span key={ index }>
            <span
              style={ {
                color: "blue",
                cursor: "pointer",
                marginLeft: 10,
                fontSize: 13,
                fontWeight:
                  activeUserPlaylist && activeUserPlaylist.rowKey === data1.rowKey
                    ? "bold"
                    : "normal"
              } }
              onClick={ () => {
                dispatch(setActiveUserPlaylist(data1));
              } }
            >
              { data1.rowKey }
            </span>
            { activeUserPlaylist && activeUserPlaylist.rowKey === data1.rowKey && (
              <IconButton
                title={ "删除歌单" }
                onClick={ () => deleteList() }
                iconProps={ { iconName: "Delete" } }
              ></IconButton>
            ) }
            <span style={ { marginLeft: 10, color: "gray" } }>{ "|" }</span>
          </span>
        );
      }
    }

    if (renderedPlaylist.length === 0) {
      renderedPlaylist.push(
        <div>没有找到自定义歌单，可以到敬拜歌曲页面把歌加入歌单</div>
      );
    }
    return renderedPlaylist;
  };

  const renderSharedPlaylists = () => {
    const renderedPlaylist = [] as any;
    if (sharedUserPlaylists) {
      let sharedUserPlaylistsArray = sharedUserPlaylists as Array<any>;
      sharedUserPlaylistsArray.sort((a: any, b: any) => {
        if (a.partitionKey === b.partitionKey) {
          return a.rowKey > b.rowKey ? 1 : -1;
        }

        return a.partitionKey > b.partitionKey ? 1 : -1;
      })

      let lastUserAccount = "";
      for (let index = 0; index < sharedUserPlaylistsArray.length; index++) {
        const data1 = sharedUserPlaylists[index];

        if (lastUserAccount !== data1.partitionKey) {
          renderedPlaylist.push(<div key={ "section " + index } style={ { marginTop: "10px" } }>{ data1.ownerDisplayName }</div>);
          lastUserAccount = data1.partitionKey;
        }
        renderedPlaylist.push(
          <span key={ index }>
            <span
              style={ {
                color: "blue",
                cursor: "pointer",
                marginLeft: 10,
                fontSize: 13,
                fontWeight: "normal"
              } }
              onClick={ () => {
                dispatch(setActiveUserPlaylist(data1));
              } }
            >
              { data1.rowKey }
            </span>
            <span style={ { marginLeft: 10, color: "gray" } }>{ "|" }</span>
          </span>
        );
      }
    }

    return renderedPlaylist;
  };

  const getYouTubeId = (youTubeUrl: string) => {
    let indexOfId = 0;
    if (youTubeUrl) {
      indexOfId = youTubeUrl.lastIndexOf("v=");
      if (indexOfId < 0) {
        indexOfId = youTubeUrl.lastIndexOf("/");
      } else {
        indexOfId += 1;
      }
    }

    if (indexOfId > 0 && youTubeUrl.length - 1 > indexOfId) {
      const youTubeId = youTubeUrl.substring(indexOfId + 1);
      return youTubeId;
    }

    return undefined;
  };

  const generateYoutubeUrl = async () => {
    if (activeUserPlaylist && activeUserPlaylist.songTitles) {
      let youtubeIds = "";
      for (let index = 0; index < activeUserPlaylist.songTitles.length; index++) {
        if (activeUserPlaylist.songTitles[index] && activeUserPlaylist.songTitles[index].length > 0) {
          try {
            const song = await SongUtilityV2.getSong(activeUserPlaylist.songTitles[index]);

            if (song.youtubeUrls) {
              for (let index = 0; index < song.youtubeUrls.length; index++) {
                const youtubeId = getYouTubeId(song.youtubeUrls[index]);

                if (youtubeId) {
                  if (youtubeIds.length > 0) {
                    youtubeIds += ",";
                  }
                  youtubeIds += youtubeId;
                }
              }
            }
          } catch { }
        }
      }
      setYoutubeUrl(
        "https://www.youtube.com/embed?autoplay=1&playlist=" + youtubeIds
      );
    }
  };

  const refreshOneSchedule = async () => {
    await EasySlidesScheduleUtilty.refreshSchedule(
      activeUserPlaylist.rowKey
    );
    await loadUserPlaylists();
  };

  const refreshSchedules = async () => {
    await EasySlidesScheduleUtilty.refreshSchedules();
    await loadUserPlaylists();
  };

  const setShareStatus = async (activeUserPlaylist: any) => {
    const newPlaylist = {
      ...activeUserPlaylist,
      shared: !activeUserPlaylist.shared
    }
    await PlaylistUtility.UpsertPlaylist(newPlaylist);
    await loadUserPlaylists();
  };

  return (
    <Fragment>
      { UserService.AuthorizeUserRole(userRoles, UserRolesEnum.Read) && (
        <div style={ { height: "100%" } }>
          <div>
            <span onClick={ () => { setListMode(0); dispatch(setActiveUserPlaylist(undefined as any)); } } style={ { marginTop: 10, marginLeft: 10, color: listMode === 0 ? "black" : "blue", cursor: "pointer" } }>{ "Easyslides 歌单" }</span>
            <span onClick={ () => { setListMode(1); dispatch(setActiveUserPlaylist(undefined as any)); } } style={ { marginTop: 10, marginLeft: 10, color: listMode === 1 ? "black" : "blue", cursor: "pointer" } }>{ "共享歌单" }</span>
            <span onClick={ () => { setListMode(2); dispatch(setActiveUserPlaylist(undefined as any)); } } style={ { marginTop: 10, marginLeft: 10, color: listMode === 2 ? "black" : "blue", cursor: "pointer" } }>{ "自定义歌单" }</span>
          </div>
          { listMode === 0 &&
            <div
              style={ {
                paddingLeft: 10,
                paddingRight: 10,
                marginTop: 10,
                marginBottom: 10
              } }
            >
              <div style={ { maxHeight: '200px', overflow: 'scroll' } }>
                <EasyslidesSchedulesView />
              </div>
              <span
                onClick={ refreshSchedules }
                style={ { marginLeft: "5px", color: "blue", cursor: "pointer" } }
              >
                Refresh
              </span>
            </div>
          }
          { listMode === 1 &&
            <div
              style={ {
                paddingLeft: 10,
                paddingRight: 10,
                marginTop: 10,
                marginBottom: 10
              } }
            >
              { renderSharedPlaylists() }
            </div>
          }
          { listMode === 2 &&
            <div
              style={ {
                paddingLeft: 10,
                paddingRight: 10,
                marginTop: 10,
                marginBottom: 10
              } }
            >
              { renderPlaylists() }
            </div>
          }
          { activeUserPlaylist && (
            <>
              <div style={ { marginTop: 10, marginLeft: 10 } }>
                <span>{ activeUserPlaylist.rowKey }</span>
                <UserPlaylistCommandBarView
                  key={ 'cmdbar' }
                  onRefresh={ refreshOneSchedule }
                  url={ youtubeUrl }
                  isCurrentUser={
                    activeUserPlaylist.partitionKey === currentUser.email
                      ? true
                      : false
                  }
                  playlist={ activeUserPlaylist } setShareStatus={ setShareStatus }></UserPlaylistCommandBarView>
              </div>
              <UserPlaylistSongsView
                songTitles={ activeUserPlaylist.songTitles }
                isCurrentUser={
                  activeUserPlaylist.partitionKey === currentUser.email
                    ? true
                    : false
                }
              ></UserPlaylistSongsView>
            </>
          ) }
        </div>
      ) }
    </Fragment>
  );
};

export default UserPlaylistsView;
