export const SEARCH_PRAISEEVENT = "SEARCH_PRAISEEVENT";
export const SET_ACTIVESONG = "SET_ACTIVESONG";
export const SET_ACTIVEUSERPLAYLIST = "SET_ACTIVEUSERPLAYLIST";
export const SET_USERPLAYLISTS = "SET_USERPLAYLISTS";
export const SET_SONGS = "SET_SONGS";
export const SET_SONGSEARCHRESULT = "SET_SONGSEARCHRESULT";

export interface PraiseEvent {
  eventId: number;
  eventName: string;
  eventStartDate: string;
  eventEndDate: string;
  eventType: string;
}

export interface PlaylistSong extends Song {
  playOrder: number;
}

export interface Song {
  partitionKey: string;
  id: string;
  folder: string;
  title1: string;
  title2: string;
  lyrics: string;
  youtubeUrls: string[];
  sheetMusicUrl: string;
  playlistId: number;
  sequence: string;
  totalRows: number;
  tags: string;
  sheetMusicFiles: { uri: string, blobName: string }[]
}

export interface Playlist {
  partitionKey: string;
  rowKey: string;
  shared: boolean;
  playlistDate: Date;
  ownerDisplayName: string;
  songTitles: string[];
}

export const setSongSearchResult = (songSearchResult: string[]) => {
  return {
    type: SET_SONGSEARCHRESULT,
    songSearchResult: songSearchResult
  }
}

export const setSongs = (songs: Song[]) => {
  return {
    type: SET_SONGS,
    songs: songs
  }
}

export const setActiveSong = (song: Song) => {
  return {
    type: SET_ACTIVESONG,
    song: song
  }
}

export const setActiveUserPlaylist = (userPlaylist: Playlist) => {
  return {
    type: SET_ACTIVEUSERPLAYLIST,
    activeUserPlaylist: userPlaylist
  }
}

export const setUserPlaylists = (userPlaylists: Playlist[]) => {
  return {
    type: SET_USERPLAYLISTS,
    userPlaylists: userPlaylists
  }
}
