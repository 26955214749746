import React, { useEffect, useState } from "react";
declare interface SongSheetMusicsViewProp {
    sheetMusicFiles: { songTitle: string, sheetMusics: { url: string, blobName: string }[] }
}

const SongSheetMusicsListView = (props: SongSheetMusicsViewProp) => {
    const [uris, setUris] = useState<string[]>([]);

    useEffect(() => {
        if (props.sheetMusicFiles && props.sheetMusicFiles.sheetMusics) {
            const result: string[] = [];
            for (let index = 0; index < props.sheetMusicFiles.sheetMusics.length; index++) {
                if (props.sheetMusicFiles.sheetMusics[index].url) {
                    result.push(props.sheetMusicFiles.sheetMusics[index].url);
                }
            }
            setUris(result);
        }
    }, [props.sheetMusicFiles]);

    return (
        <>
            { uris && uris.length > 0 && uris.map(
                (item: any, index: any) => {
                    return <span key={ index }>
                        <a target="_blank" href={ item } style={ { marginRight: "3px", textDecorationLine: "none", color: "#4007a2" } }>{ "歌谱 " + (index + 1) } </a>
                    </span>
                }
            )
            }
        </>
    );
}

export default SongSheetMusicsListView;