import { CommandButton, ContextualMenu, IContextualMenuItem, Modal } from "@fluentui/react";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { ReduxAppState } from "../../../store/reducers";
import SongSheetMusicsListView from "../sheetMusic/SongSheetMusicsListView";
import AddSongToPlaylistView from "../playlist/AddSongToPlaylistView";
import { UserRolesEnum, UserService } from "../../../utility/userService";
import YoutubePlayerArea from "../../shared/YoutubePlayerArea";
import SongEditView from "./SongEditView";
import { SongLyricsView } from "./SongLyricsView";
import styles from "./SongCardView.module.css";
import SongSheetMusicsView from "../sheetMusic/SongSheetMusicsView";

export const SongCardView = (props: { song: any }) => {
    const [songTitleAddToPlaylist, setSongTitleAddToPlaylist] = useState("");
    const [isEditMode, setIsEditMode] = useState(false);
    const [showLyrics, setShowLyrics] = useState(false);
    const [showContextualMenu, setShowContextualMenu] = useState(false);
    const [showSheetMusicAdmin, setShowSheetMusicAdmin] = useState(false);

    const userRoles = useSelector((state: ReduxAppState) => {
        return state.userAccount.userProfile?.roles;
    });
    const cmRef = React.useRef(null);

    const menuItems: IContextualMenuItem[] = [
        {
            key: '1',
            text: '修改',
            onClick: () => setIsEditMode(true)
        },
        {
            key: '2',
            text: '加入歌单',
            onClick: () => setSongTitleAddToPlaylist(props.song.title1)
        },
        {
            key: '3',
            text: '歌谱管理',
            onClick: () => setShowSheetMusicAdmin(true)
        }
    ];

    const onHideContextualMenu = () => {
        setShowContextualMenu(false);
    }

    return <>
        <div style={ { marginBottom: 1, display: "inline-block", width: "100%" } }>
            <div style={ { width: "100%" } }>
                <span onClick={ () => { setShowLyrics(true) } } style={ { fontSize: 18, color: "#4007a2", cursor: "pointer", display: "inline-block", width: "350px", fontFamily: "sans-serif" } }>{ props.song.title1 }</span>
                <span ref={ cmRef }
                    style={ { color: "rgb(16, 110, 190)", cursor: "pointer", float: "right", marginRight: "3px" } }
                    onClick={ () => { setShowContextualMenu(true) } }>...</span>
                <ContextualMenu
                    items={ menuItems }
                    hidden={ !showContextualMenu }
                    target={ cmRef }
                    onItemClick={ onHideContextualMenu }
                    onDismiss={ onHideContextualMenu }
                />
            </div>
        </div>
        <div style={ { marginBottom: 1, fontSize: 14, display: "inline-block", width: "100%" } }>
            <div style={ { display: "inline-block", width: "calc(100% - 120px)" } }>
                <div style={ { color: "gray" } }>
                    { props.song.lyrics.substring(0, 100) + "..." }
                </div>
                <div style={ { color: "gray" } }>
                    关键词:<span>{ props.song.tags }</span>
                </div>
                <div style={ { color: "gray" } }>
                    <span>唱法: </span><span>{ props.song.sequence }</span>
                </div>
                <div>
                    <SongSheetMusicsListView sheetMusicFiles={ props.song.sheetMusicFiles }></SongSheetMusicsListView>
                </div>
            </div>
            <div style={ { display: "inline-block", minWidth: 120, alignItems: "top", float: "right" } }>
                <YoutubePlayerArea youtubeUrls={ props.song.youtubeUrls } />
            </div>
        </div>
        <Modal isOpen={ songTitleAddToPlaylist && songTitleAddToPlaylist.length > 0 ? true : false } styles={ { root: { padding: 10 } } }>
            <AddSongToPlaylistView songTitle={ songTitleAddToPlaylist } onClose={ () => setSongTitleAddToPlaylist("") } />
        </Modal>
        <Modal isOpen={ isEditMode } styles={ { root: { padding: 10 } } }>
            <SongEditView songId={ props.song.id } onClose={ () => { setIsEditMode(false); } } />
        </Modal>
        <Modal isOpen={ showLyrics } isBlocking={ false } onDismiss={ () => { setShowLyrics(false); } }>
            <SongLyricsView sequence={ props.song.sequence } lyrics={ props.song.lyrics } onClose={ () => { setShowLyrics(false); } } />
        </Modal>
        <Modal isOpen={ showSheetMusicAdmin } styles={ { root: { padding: 10 } } }>
            <div style={ { padding: 10 } }>
                <SongSheetMusicsView
                    song={ props.song }
                    onClose={ () => {
                        setShowSheetMusicAdmin(false);
                    } }
                ></SongSheetMusicsView>
            </div>
        </Modal>
    </>
}