import React, { useEffect, useState } from "react";
import styles from "./Songs.module.css";
import { useSelector } from "react-redux";
import { ReduxAppState } from "../../store/reducers";
import { SongView } from "./song/SongView";
import { Song } from "../../store/actions/songActions";
import { SheetMusicFileUtility } from "../../utility/v2/sheetMusicFileUtilty";

const SongsPageView = (props: {
  top: number;
  skip: number;
}) => {
  const [songsToRender, setSongsToRender] = useState<Song[]>([]);
  const songs = useSelector((state: ReduxAppState) => { return state.song.songs });
  const songSearchResult = useSelector((state: ReduxAppState) => { return state.song.songSearchResult });

  const refreshSongsAndSheetmusic = async () => {
    const result: Song[] = [];
    for (var index = 0; index < songSearchResult.length; index++) {
      if (index >= props.skip && index < props.skip + props.top) {
        const songTitle = songSearchResult[index];
        const song = songs.filter((song: Song) => song.title1 === songTitle)
        if (song && song.length > 0) {
          result.push(song[0]);
        }
      }
    }

    try {
      const response = await SheetMusicFileUtility.getSongsSheetMusicFileSasUrls(result);
      for (let index1 = 0; index1 < response.length; index1++) {
        result[index1].sheetMusicFiles = response[index1];
      }
    } catch {
      ;
    }
    setSongsToRender(result);
  }
  useEffect(() => {
    refreshSongsAndSheetmusic();
  }, [props]);

  return (
    <div className={ styles.searchresult } style={ { overflow: "auto" } }>
      { songsToRender && songsToRender.map((song: Song, index: any) => {
        return <div key={ index } style={ { marginBottom: 2, backgroundColor: "white", padding: 10 } }>
          <SongView song={ song } />
        </div>;
      }) }
    </div>
  );
}

export default SongsPageView;
